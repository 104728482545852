import {
    Send,
  } from "@mui/icons-material";
  import {
    Alert,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { useContext } from "react";
  import { useEffect } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import {  PosizionamentoContext } from "../contexts";
  import { useForm } from "../hooks";
  import ReactMarkdown from "react-markdown";
  import rehypeRaw from "rehype-raw";
  import { TableRowHtml } from "../components";

  const CAUSALE_ENTRATA = "ent";
  
  const MagLogEntrataDettaglio = (props) => {
    const navigate = useNavigate()
    const { prgMovimento } = useParams();
    const [customCol, setCustomCol] = React.useState([]);
  
    const inputRef = React.useRef();
  
    const  {
        state : {
            MovimentoColumns,
            movimento,
            infoMovimento,
        },

        getMagMovimDataEntrata,
        postRegistraMovimentoEntrata

    } = useContext(PosizionamentoContext);

  
    useEffect(() => {
      if (prgMovimento) {
        getMagMovimDataEntrata(prgMovimento,  navigate);
      }
    }, []);
  
    const validations = [];
    const {
      values: articoloForm,
      changeHandler,
      setValues,
    } = useForm(
      {
        Articolo: "",
      },
      validations
    );
  
  
  
    React.useEffect(() => {
      let colonneDaModificare = [];
      MovimentoColumns.map((res, i) => {
        colonneDaModificare = [
          ...colonneDaModificare,
          {
            field: res.id,
            headerName: res.label,
            minWidth: res.minWidth,
          },
        ];
      });
      setCustomCol(colonneDaModificare);
    }, [MovimentoColumns]);
  
    const onkeyEnter = (event) => {
      if (event === 13) {
        saveData();
      }
    };
  
    const saveData = () => {  
      postRegistraMovimentoEntrata(prgMovimento, articoloForm.Articolo);

      resetArticolo(); 
    }
  
  
    const saveDataConferma = () => {    
      postRegistraMovimentoEntrata(prgMovimento, articoloForm.Articolo);
      resetArticolo(); 
      // setTimeout(() => {
      //   inputRef.current.focus();
      // }, 100);  
    }
  
  
    const resetArticolo = () => {    
      setValues({
        Articolo: "",
      });
    }
  
  
    return (
      <Grid container>
        <Grid
          container
          sx={{
            position: "fixed",
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Segnacollo"
            variant="outlined"
            placeholder="Inserire il segnacollo"
            fullWidth
            autoFocus
            autoComplete="off"
            inputRef={inputRef}
            onChange={(e) => changeHandler("Articolo", e.target.value)}
            value={articoloForm.Articolo || ""}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => saveData()}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
  
        <Grid container mt={8}>
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="info" component="span">
              {
                <ReactMarkdown
                  children={
                    infoMovimento !== ''
                      ? infoMovimento
                      : ""
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
            <Alert severity="warning" component="span">
              {
                <ReactMarkdown
                  children={
                    infoMovimento?.summarytext
                      ? infoMovimento.summarytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }
            </Alert>
            <Grid item xs={12} md={12} lg={12}>
              {customCol?.length > 0 ? (
                <TableRowHtml customCol={customCol} rows={movimento} />
              ) : (
                <></>
              )}
            </Grid>
  
          </Grid>
          
        </Grid> 

  

       
      </Grid>
    );
  };
  
  export default MagLogEntrataDettaglio;
  